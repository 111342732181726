const SUBSCRIBED_MUTATIONS = (type) => ({
  'applications/form/setRealEstates': executeCalculateTaxes,
  'applications/form/setSignDetails': executeCalculateTaxes,
  'applications/form/setComputedTaxFields': executeCalculateTaxes,
  'applications/form/setDiscountDetails': executeCalculateTaxes,
}[type]);

const formattedRealEstateTarget = (realEstateTarget) => {
  return realEstateTarget.map((el) => ({
    territorial_administrative_unit: el.territorial_administrative_unit,
    territorial_administrative_unit_id: el.territorial_administrative_unit_id,
    land_area: el.land_area,
    category_code: el.category_code,
    real_estate_type: el.real_estate_type,
  })) || [];
}

const getCalculateTaxesPayload = (state) => {
  const { realEstateTarget, signDetails, selectedApplicationType, computedTaxFields, discountDetails } = state;
  const realEstateTargetValues = formattedRealEstateTarget(realEstateTarget);

  const values = {
    real_estate_target: realEstateTargetValues,
    sign_details: signDetails,
    form: computedTaxFields,
  }

  if (discountDetails?.id) {
    values.discount_id = discountDetails.id;
  }

  return {
    id: selectedApplicationType,
    values,
  };
}

const checkSignDetails = (signDetails) => {
  return !(signDetails.isOnline && !signDetails.package);
}

const executeCalculateTaxes = (store, state) => {
  const { selectedApplicationType, signDetails } = state.applications.form;
  if (!selectedApplicationType
    || !checkSignDetails(signDetails)) return false;
  const payload = getCalculateTaxesPayload(state.applications.form);
  store.dispatch('applications/form/calculateTaxes', payload);
}

export default {
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      const record = state.applications.form.record;

      // execute calculate taxes methods only if there is no record (new application)
      // or the application is not paid (edit application)
      if (!record || (record && !record.is_paid)) {
        const { type, payload } = mutation;
        const executedMethod = SUBSCRIBED_MUTATIONS(type);
        if (executedMethod) {
          executedMethod(this.$store, state, payload);
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}