<template lang="">
    <div id="summary">
        <div class="application-section-title" v-if="!viewMode">{{ $t('APPLICATION.SUMMARY.SECTION_TITLE') }}</div>
        <application-summary-details
            :formModels="formModels"
            :signDetails="signDetails"
            :paymentDetails="paymentDetails"
            :requiredRealEstate="requiredRealEstate"
        ></application-summary-details>
        <application-payment-summary
            :formModels="formModels"
            :signDetails="signDetails"
            :paymentDetails="paymentDetails"
            :taxCategoryName="taxCategoryName"
        ></application-payment-summary>
        <div class="d-flex align-items-center justify-content-center mt-6 application-total">{{$t('APPLICATION.SUMMARY.TOTAL') }}: <span class="application-total-sum">{{ `${totalTaxes} ${$t('GENERAL.CURRENCY_LABEL')}` }}</span>
            <span v-if="isPaid" class="text-success application-payment-status">{{ $t('APPLICATION.PAYMENT.STATUS_PAID')}}</span>
            <span v-else class="text-danger application-payment-status">{{ $t('APPLICATION.PAYMENT.STATUS_UNPAID')}}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicationSummary',
    components: {
        ApplicationSummaryDetails: () => import('@/modules/applications/components/summary/ApplicationSummaryDetails'),
        ApplicationPaymentSummary: () => import('@/modules/applications/components/summary/ApplicationPaymentSummary'),
    },
    props: {
        formModels: Object,
        signDetails: Object,
        paymentDetails: Object,
        taxCategoryName: String,
        viewMode: {
            type: Boolean,
            default: false,
        },
        requiredRealEstate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            totalTaxes: "applications/form/totalTaxes",
        }),
        isPaid() {
            return !!this.record?.is_paid;
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.$emit('child-mounted');
        });
    },
}
</script>
