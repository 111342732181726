export default {
    data() {
        return {};
    },
    computed: {
        disablePayment() {
            if (this.record) {
                return (
                    (this.signDetails && 
                        !this.signDetails.isOnline) &&
                    (this.record.sign_details && 
                        !this.record.sign_details.isOnline) &&
                    !this.record.is_signed
                );
            }
            return false;
        },
        offlineSigningText() {
            return (this.loading["applications/initializeOfflineSign"] || this.loading["applications/previewDocumentByCitizen"])
                ? this.$t("APPLICATION.NOTIFIERS.GENERATE_OFFLINE_PACKAGE")
                : "";
        },
    },
    methods: {
        getFileData(formData) {
            const fileItems = {};
            const existentFiles = {}
            Object.keys(formData).forEach((key) => {
                if (key !== "attachments") {
                    const formItem = formData[key];
                    if (
                        Array.isArray(formItem) &&
                        formItem.length &&
                        formItem[formItem.length - 1] instanceof File
                    ) {
                        let areNewFiles = false;
                        const formDataObj = new FormData();
                        const fileList = []
                        for (let i = 0; i < formItem.length; i++) {
                            const file = formItem[i];
                            if (file instanceof File) {
                                formDataObj.append("files", file);
                                areNewFiles = true;
                            } else {
                                fileList.push(file);
                            }
                        }
                        existentFiles[key] = fileList
                        if (areNewFiles) {
                            fileItems[key] = formDataObj;
                        }
                        delete formData[key];
                    }
                }
            });

            if (formData?.attachments?.length) {
                let areNewFiles = false;
                const formDataObj = new FormData();
                const existingFiles = [];

                for (let i = 0; i < formData?.attachments?.length; i++) {
                    const file = formData.attachments[i];
                    if (file instanceof File) {
                        formDataObj.append("files", file);
                        areNewFiles = true;
                    } else {
                        existingFiles.push(file);
                    }
                }
                if (areNewFiles) {
                    fileItems["attachments"] = formDataObj;
                }

                if (existingFiles.length) {
                    fileItems["identification_files"] = existingFiles;
                }

                delete formData.attachments;
            }
            return {fileItems, existentFiles};
        },
    },
};

