import axios from 'axios';

export const axiosInlineDownload = async ({url, label, type}) => {
    return axios.get(url, {responseType: 'blob'})
        .then(response => {
            let blob;
            if(type) {
                blob = new Blob([response.data], {type});
            } else {
                blob = new Blob([response.data]);
            }
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
        }).catch(console.error)
}
